import Footer from '../include/Footer';
import Header from '../include/Header';

function Home() {
  return (
    <>
      <Header />
      <div className="container mx-auto py-6 flex-1 flex flex-col justify-center">
        <div className="flex justify-between items-center gap-20 md:gap-44 flex-wrap md:flex-nowrap">
          <div className="md:basis-2/4">
            <div className="text-4xl text-primary">Je challenge mon <b>leadership</b> !</div>
            <div className="text-2xl text-gray-700 font-light mt-8">
            En seulement 5 minutes, mesurez le pouls de votre entreprise grâce à notre plate-forme VISCONTI PulsePro et repartez avec des plans d'action sur-mesure, s'alignant sur les critères clés des meilleurs leaders
            </div>
            <div className="mt-5">
              <a href="/questionnaire"><button className="h-10 px-6 rounded-md border bg-primary text-white font-semibold" type="button">Démarrer</button></a>
            </div>
          </div>
          <div className="md:basis-2/4 flex justify-end pl-10">
            <img src="/assets/vp_lst_home.png" alt="" style={{maxWidth: '100%', maxHeight: 600, boxShadow: '25px -25px 0px 0px #000f9f', margin: 25}} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
