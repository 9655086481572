import React, { useState, useEffect } from 'react';
import Footer from '../include/Footer';
import Header from '../include/Header';
import Resultat from './Resultat';

function Questionnaire() {
  const [questions, setQuestions] = useState([]);
  const [questionsLoaded, setQuestionsLoaded] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [answers, setAnswers] = useState({});
  const [currentAnswer, setCurrentAnswer] = useState(4);

  const [stateLead, setStateLead] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [subscriptionNewsletter, setSubscriptionNewsletter] = useState(false);
  const [coached, setCoached] = useState(false);
  const [coachName, setCoachName] = useState("");

  const [results, setResults] = useState(null);
  const [galaxyToken, setGalaxyToken] = useState(null);
  const [pageResults, setPageResults] = useState(false);
  const [resultsLoading, setResultsLoading] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/questions`)
      .then(response => response.json())
      .then(responseData => {
          setQuestions(responseData);
          setQuestionsLoaded(true);
          setCurrentQuestion(responseData[0]);
          setCurrentQuestionIndex(0);
      })
      .catch(error => {
        console.log('Error fetching and parsing data', error);
      });
  }, []);

  useEffect(() => {
    if (questions) {
      setCurrentQuestion(questions[currentQuestionIndex]);
    }
  }, [currentQuestionIndex]);

  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {
      if (answers[questions[currentQuestionIndex-1].id]) {
        setCurrentAnswer(answers[questions[currentQuestionIndex-1].id]);
      } else {
        setCurrentAnswer(4);
        storeAnswer(questions[currentQuestionIndex-1], 4);
      }
      setCurrentQuestionIndex(currentQuestionIndex-1);
    }
  }

  const nextQuestion = () => {
    if (currentQuestionIndex < questions.length-1) {
      if (answers[questions[currentQuestionIndex+1].id]) {
        setCurrentAnswer(answers[questions[currentQuestionIndex+1].id]);
      } else {
        setCurrentAnswer(4);
        storeAnswer(questions[currentQuestionIndex+1], 4);
      }
      setCurrentQuestionIndex(currentQuestionIndex+1);
    }
  }

  const storeAnswer = (q, v) => {
    const newAnswers = {...answers};
    newAnswers[q.id] = v;

    setAnswers(newAnswers);
    setCurrentAnswer(v);
  }

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const getResults = () => {
    if (answers && firstName && lastName && email && position && (!coached || coachName)) {
      if (validateEmail(email)) {
        setPageResults(true);
        setResultsLoading(true);

        let formData = new FormData();
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('company', company);
        formData.append('position', position);
        formData.append('answers', JSON.stringify(answers));
        formData.append('subscriptionNewsletter', subscriptionNewsletter);
        formData.append('coachName', coachName);

        fetch(`${process.env.REACT_APP_PUBLIC_API_URL}/results`, {
          method: 'POST',
          body: formData
        })
        .then(response => response.json())
        .then(responseData => {
          const responseDataJson = JSON.parse(responseData);
          setResults(responseDataJson.results);
          setGalaxyToken(responseDataJson.galaxyToken);
          setResultsLoading(false);
        });
      } else {
        alert("Veuillez indiquer une adresse e-mail valide.");
      }
    } else {
      alert("Veuillez compléter tous les champs.");
    }
  }

  return (
    <>
      <Header />
      {pageResults ? (
        <div className="container mx-auto py-6 flex-1">
          {resultsLoading ? (
            <div className="mt-16 text-center text-xl mx-auto" style={{maxWidth: 1000}}>Chargement des résultats...</div>
          ) : (
            <Resultat results={results} galaxyToken={galaxyToken} />
          )}
        </div>
      ) : (
        <div className="container mx-auto py-6 flex-1">
          {questionsLoaded && questions ? (
            <>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div className="bg-primary h-2.5 rounded-full" style={{transition: 'width 0.3s ease-in-out', width: ((currentQuestionIndex/(questions.length-1))*100)+'%'}}></div>
              </div>
              {!stateLead && currentQuestion && (
                <>
                  <div className="mt-16 text-center text-xl mx-auto" style={{maxWidth: 1000}}>
                    {currentQuestion.label}
                  </div>
                  <div className="m-auto flex justify-center items-center gap-4 md:gap-10 mt-16 flex-col md:flex-row">
                    <div className="text-sm hidden md:block">pas du tout d'accord</div>
                    <div className="flex justify-center items-start gap-4">
                      {[1,2,3,4,5,6,7].map((value, index) => (
                        <div key={index} className="flex flex-col justify-between items-center">
                          <input type="radio" id={"radio-"+index} name={"question-"+currentQuestion.id} value={value} onChange={() => storeAnswer(currentQuestion, value)} checked={value == currentAnswer}/>
                          <label htmlFor={"radio-"+index} className="font-semibold">{value}</label>
                        </div>
                      ))}
                    </div>
                    <div className="text-sm hidden md:block">tout à fait d'accord</div>
                    <div className="flex md:hidden gap-10">
                      <div className="text-xs">pas du tout d'accord</div>
                      <div className="text-xs">tout à fait d'accord</div>
                    </div>
                  </div>
                  <div className="flex justify-center items-start gap-6 mt-24">
                    {currentQuestionIndex > 0 && (
                      <button onClick={() => previousQuestion()} className="h-10 px-6 rounded-md border bg-white border-slate-200 text-slate-900 text-sm" type="button">&#x2190; &nbsp; Précédent</button>
                    )}
                    {currentQuestionIndex < questions.length-1 ? (
                      <button onClick={() => nextQuestion()} className="h-10 px-6 rounded-md border bg-primary text-white text-sm" type="button">Suivant &nbsp; &#x2192;</button>
                    ) : (
                      <button onClick={() => setStateLead(true)} className="h-10 px-6 rounded-md border bg-primary text-white text-sm" type="button">Terminer &nbsp; &#x2192;</button>
                    )}
                  </div>
                </>
              )}
              {stateLead && (
                <>
                  <div className="mt-16 text-center text-xl mx-auto" style={{maxWidth: 1000}}>
                    Vos cordonnées
                  </div>
                  <div className="mt-2 text-center text-xs mx-auto text-gray-600" style={{maxWidth: 1000}}>
                    Pour accéder à vos résultats, veuillez indiquer vos coordonnée dans les champs ci-dessous
                  </div>
                  <div className="mt-14 mx-auto" style={{maxWidth: 1000}}>
                    <form className="w-full max-w-lg mx-auto">
                      <div className="flex flex-wrap -mx-3 mb-3">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                            Prénom<sup className="text-red-700">*</sup>
                          </label>
                          <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Nom<sup className="text-red-700">*</sup>
                          </label>
                          <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-3">
                        <div className="w-full px-3">
                          <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-email">
                            Email<sup className="text-red-700">*</sup>
                          </label>
                          <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-3">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                          <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-company">
                            Entreprise<sup className="text-red-700">*</sup>
                          </label>
                          <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-company" type="text" value={company} onChange={(e) => setCompany(e.target.value)} required />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                          <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-position">
                            Fonction<sup className="text-red-700">*</sup>
                          </label>
                          <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-position" type="text" value={position} onChange={(e) => setPosition(e.target.value)} required />
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                          <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-position">
                            Êtes-vous accompagné par VISCONTI ?
                          </label>
                          <div className="flex">
                            <div className="mr-4">
                              <input type="radio" id="coaching_no" name="drone" value={false} checked={!coached} onChange={(e) => setCoached(false)} />
                              <label for="coaching_no" className="ml-1 text-sm">Non</label>
                            </div>
                            <div>
                              <input type="radio" id="coaching_yes" name="drone" value={true} checked={coached} onChange={(e) => setCoached(true)} />
                              <label for="coaching_yes" className="ml-1 text-sm">Oui</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {coached && (
                        <div className="flex flex-wrap -mx-3 mb-3">
                          <div className="w-full px-3">
                            <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-email">
                              Nom du coach VISCONTI<sup className="text-red-700">*</sup>
                            </label>
                            <input className="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="email" value={coachName} onChange={(e) => setCoachName(e.target.value)} required />
                          </div>
                        </div>
                      )}
                      <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3 flex">
                          <input className="bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-newsletter" type="checkbox" value={subscriptionNewsletter} onChange={(e) => setSubscriptionNewsletter(e.target.checked)} />
                          <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 ms-4" htmlFor="grid-newsletter">
                            Je souhaite m'abonner à la newsletter de VISCONTI. J'ai conscience que je peux me désabonner à tout moment de cette newsletter en utilisant le lien de désabonnement fourni dans chaque e-mail.
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="flex justify-center items-start gap-6">
                    <button disabled={!firstName || !lastName || !email || !position} onClick={() => getResults()} className="h-10 px-6 rounded-md border bg-primary text-white text-sm" type="button">Voir les résultats &nbsp; &#x2192;</button>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="mt-16 text-center text-xl mx-auto" style={{maxWidth: 1000}}>Chargement...</div>
          )}
        </div>
      )}
      <Footer />
    </>
  );
}

export default Questionnaire;
