import logo from '../assets/logo.svg';

function Header() {
  return (
      <header className="bg-gray-50 border-b">
        <div className="container mx-auto flex justify-between items-center py-6">
          <a href="/"><img src={logo} className="h-10" alt="logo" /></a>
          <div className="font-semibold text-primary text-sm md:text-xl mt-2 text-center">VISCONTI PulsePro</div>
          <a href="https://visconti.partners/"><button className="h-10 px-6 rounded-md border bg-white border-slate-200 text-slate-900 text-sm" type="button">Retour</button></a>
        </div>
      </header>
  );
}

export default Header;
